.recurly {
  display: block;
  position: relative;
  width: 500px;
  max-width: 100%;
  margin: 0px auto;
}
.recurly .cost,
.recurly .discount {
  font-size: 16px;
  text-align: right;
}
.recurly .subscription {
  border-radius: 9px 9px 0 0;
  text-shadow: 0 1px 0 #fff;
  padding-top: 20px;
  overflow: hidden;
}
.recurly .plan {
  color: #333;
  overflow: hidden;
  position: relative;
  zoom: 1;
}
.recurly .plan .name {
  float: left;
  font-size: 32px;
  min-width: 200px;
  padding-left: 20px;
  padding-right: 40px;
}
.recurly .plan .quantity.field {
  clear: none;
  width: 60px;
  margin: 4px 0;
}
.recurly .plan .quantity.field input[type=text] {
  width: 48px;
}
.recurly .plan .quantity.field:before {
  content: "\d7";
  height: 48px;
  line-height: 30px;
  position: absolute;
  right: 100%;
  width: 40px;
  font-size: 20px;
  text-align: center;
  vertical-align: middle;
  z-index: 1337;
  color: #666;
}
.recurly .plan .recurring_cost {
  float: right;
  text-align: right;
  padding-right: 20px;
}
.recurly .plan .recurring_cost .cost {
  font-size: 32px;
}
.recurly .plan .recurring_cost .interval {
  font-size: 12px;
  padding-bottom: 20px;
}
.recurly .free_trial {
  clear: left;
  float: left;
  font-size: 13px;
  height: 22px;
  margin: 0;
  position: absolute;
  top: 35px;
  left: 20px;
  font-style: italic;
}
.recurly .setup_fee {
  clear: both;
  background: url("recurly/dash.png") repeat-x 1px top;
  overflow: hidden;
  padding-top: 20px;
}
.recurly .setup_fee .title {
  float: left;
  padding-left: 20px;
  font-weight: bold;
  font-size: 16px;
}
.recurly .setup_fee .cost {
  float: right;
  padding-right: 20px;
}
.recurly .vat {
  height: 24px;
  padding: 20px 20px;
  display: none;
  background: url("recurly/dash.png") repeat-x 1px top;
}
.recurly .vat.applicable {
  display: block;
}
.recurly .vat .title {
  font-size: 16px;
  font-weight: normal;
  float: left;
}
.recurly .vat .cost {
  float: right;
  font-size: 18px;
}
.recurly .add_ons {
  clear: both;
}
.recurly .add_ons.any {
  margin: 20px 10px;
}
.recurly .add_ons .add_on {
  background: #ecedee;
/* 
  background: -webkit-linear-gradient(top, #ecedee, #e5e6e7);
  background: -moz-linear-gradient(top, #ecedee, #e5e6e7);
  background: -o-linear-gradient(top, #ecedee, #e5e6e7);
  background: linear-gradient(top, #ecedee, #e5e6e7);
 */
  margin: 0;
  height: 43px;
  line-height: 42px;
  vertical-align: middle;
  position: relative;
  clear: both;
  overflow: hidden;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  text-shadow: 0 1px 0 #fff;
  color: #999;
  font-weight: 300;
  font-size: 16px;
  zoom: 1;
  cursor: default;
}
.recurly .add_ons .add_on.first {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.recurly .add_ons .add_on.last {
  border-bottom: 1px solid #ccc;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.recurly .add_ons .add_on .name {
  font-size: inherit;
  font-weight: inherit;
  font-style: italic;
  color: inherit;
  width: 200px;
  margin-left: 9px;
  margin-right: 20px;
  position: absolute;
  left: 0;
  top: 0;
}
.recurly .add_ons .add_on .quantity.field {
  position: absolute;
  top: 4px;
  left: 249px;
  width: 60px;
  display: none;
}
.recurly .add_ons .add_on .quantity.field input[type=text] {
  width: 48px;
}
.recurly .add_ons .add_on .quantity.field:before {
  content: "\d7";
  height: 48px;
  line-height: 30px;
  position: absolute;
  right: 100%;
  width: 40px;
  font-size: 20px;
  text-align: center;
  vertical-align: middle;
  z-index: 1337;
  color: #666;
}
.recurly .add_ons .add_on .cost {
  font-size: inherit;
  line-height: inherit;
  vertical-align: middle;
  position: absolute;
  right: 10px;
}
.recurly .add_ons .add_on:hover {
/* 
  background: -webkit-linear-gradient(top, #f0f0f0 0%, #dfdfdf 50%, #d5d5d5 50%, #e0e0e0 100%);
  background: -moz-linear-gradient(top, #f0f0f0 0%, #dfdfdf 50%, #d5d5d5 50%, #e0e0e0 100%);
  background: -o-linear-gradient(top, #f0f0f0 0%, #dfdfdf 50%, #d5d5d5 50%, #e0e0e0 100%);
  background: linear-gradient(top, #f0f0f0 0%, #dfdfdf 50%, #d5d5d5 50%, #e0e0e0 100%);
 */
  box-shadow: inset 0 1px 0 #fff;
  text-shadow: none;
  color: #111;
}
.recurly .add_ons .add_on:active,
.recurly .add_ons .add_on.selected {
  color: #111;
/* 
  background: -webkit-linear-gradient(top, #f0f0f0, #fff);
  background: -moz-linear-gradient(top, #f0f0f0, #fff);
  background: -o-linear-gradient(top, #f0f0f0, #fff);
  background: linear-gradient(top, #f0f0f0, #fff);
 */
  width: auto;
  box-shadow: inset 0 1px 4px 0 rgba(0,0,0,0.07);
  text-shadow: none;
}
.recurly .add_ons .add_on.selected {
  background: #fff url("recurly/check.png") no-repeat 10px center;
}
.recurly .add_ons .add_on.selected .name {
  padding-left: 24px;
}
.recurly .add_ons .add_on.selected:hover {
  background: #fcf5f0 url("recurly/uncheck.png") no-repeat 10px center;
}
.recurly .add_ons .add_on.selected .quantity {
  display: block;
}
.recurly .coupon {
  clear: both;
  overflow: hidden;
  height: 34px;
  color: #333;
  padding: 20px 20px;
  position: relative;
  background: url("recurly/dash.png") repeat-x 1px top;
}
.recurly .coupon .check {
  width: 26px;
  height: 26px;
  float: left;
  border-radius: 15px 15px 15px 15px;
  background: #70ccf8;
  border: 1px solid #0090c9;
  margin: 3px 0 1px 10px;
  box-shadow: inset 0 1px 0 0 rgba(255,255,255,0.35), 0 1px 1px 0 rgba(0,0,0,0.10);
  background: #43bef9 url("recurly/coupon_check.png") no-repeat center center;
/* 
  background: url("recurly/coupon_check.png") no-repeat center center, -webkit-linear-gradient(top, #71CDFA 0%, #43BEF9 50%, #00B1F6 50%, #71CEFB 100%);
  background: url("recurly/coupon_check.png") no-repeat center center, -moz-linear-gradient(top, #71CDFA 0%, #43BEF9 50%, #00B1F6 50%, #71CEFB 100%);
  background: url("recurly/coupon_check.png") no-repeat center center, -o-linear-gradient(top, #71CDFA 0%, #43BEF9 50%, #00B1F6 50%, #71CEFB 100%);
  background: url("recurly/coupon_check.png") no-repeat center center linear-gradient(top, #71CDFA 0%, #43BEF9 50%, #00B1F6 50%, #71CEFB 100%);
 */
}
.recurly .coupon .check:hover {
/* 
  background: url("recurly/coupon_check.png") no-repeat center center, -webkit-linear-gradient(top, #71CDFA 0%, #43BEF9 50%, #00B1F6 50%, #71CEFB 100%);
  background: url("recurly/coupon_check.png") no-repeat center center, -moz-linear-gradient(top, #71CDFA 0%, #43BEF9 50%, #00B1F6 50%, #71CEFB 100%);
  background: url("recurly/coupon_check.png") no-repeat center center, -o-linear-gradient(top, #71CDFA 0%, #43BEF9 50%, #00B1F6 50%, #71CEFB 100%);
  background: url("recurly/coupon_check.png") no-repeat center center linear-gradient(top, #71CDFA 0%, #43BEF9 50%, #00B1F6 50%, #71CEFB 100%);
 */
  box-shadow: inset 0 1px 0 0 rgba(255,255,255,0.75), 0 1px 1px 0 rgba(0,0,0,0.10);
}
.recurly .coupon .check:active {
/* 
  background: url("recurly/coupon_check.png") no-repeat center center, -webkit-linear-gradient(top, #f0f0f0, #fff);
  background: url("recurly/coupon_check.png") no-repeat center center, -moz-linear-gradient(top, #f0f0f0, #fff);
  background: url("recurly/coupon_check.png") no-repeat center center, -o-linear-gradient(top, #f0f0f0, #fff);
  background: url("recurly/coupon_check.png") no-repeat center center linear-gradient(top, #f0f0f0, #fff);
 */
  box-shadow: inset 0 3px 3px 0 rgba(0,0,0,0.03);
  border: 1px solid #999;
}
.recurly .coupon.checking .check {
  background: #f0f0f0 url("recurly/coupon_checking.gif") no-repeat center center;
  box-shadow: inset 0 3px 3px 0 rgba(0,0,0,0.03);
  border: 1px solid #999;
}
.recurly .coupon.invalid .coupon_code {
  border-color: #a55;
  background: #fee;
  color: #311;
}
.recurly .coupon .coupon_code .error {
  left: 300px;
}
.recurly .coupon .description {
  float: left;
  margin-left: 20px;
  height: 34px;
  line-height: 34px;
  vertical-align: middle;
  font-size: 14.4px;
}
.recurly .coupon .discount {
  float: right;
  height: 34px;
  line-height: 34px;
  vertical-align: middle;
}
.recurly .error {
  padding: 5px;
  line-height: 22px;
  vertical-align: middle;
  color: #000;
  text-shadow: 0 1px 0 #fec;
  background: #ffc;
  border: 1px solid #ba1;
  box-shadow: 3px 5px 5px 0 rgba(0,0,0,0.10);
  border-radius: 5px;
  font-size: 13px;
}
.recurly .server_errors {
  color: #fff;
  text-shadow: 0 1px 0 #000;
  margin: 0 20px;
  opacity: 0;
}
.recurly .server_errors .error {
  padding-left: 26px;
  background: rgba(240,250,0,0.50) url("recurly/error.png") no-repeat 5px 9px;
}
.recurly .server_errors.any {
  opacity: 1;
  -webkit-transition: opacity 0.5s linear;
  -moz-transition: opacity 0.5s linear;
  margin: 20px 20px;
  margin-bottom: 0;
}
.recurly .contact_info,
.recurly .billing_info,
.recurly .accept_tos {
  position: relative;
  padding: 20px 20px;
  overflow: hidden;
  zoom: 1;
}
.recurly .title {
  font-size: 16px;
  height: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  color: #404041;
  text-shadow: 0 1px 0 #fff;
  float: left;
}
.recurly .credit_card,
.recurly .paypal {
  clear: both;
}
.recurly .payment_method {
  margin-bottom: 20px;
  width: 300px;
}
.recurly .payment_method .payment_option {
  float: right;
}
.recurly .payment_method .payment_option input[type=radio] {
  margin-right: 10px;
  display: none;
}
.recurly .payment_method.multiple {
  height: 34px;
  clear: both;
}
.recurly .payment_method.multiple input[type=radio] {
  display: block;
  float: left;
  height: 29px;
}
.recurly .payment_method.multiple .card_option {
  float: left;
}
.recurly .payment_method.multiple .paypal_option {
  float: right;
}
.recurly .payment_method.multiple .logo,
.recurly .payment_method.multiple .accepted_cards {
  opacity: 0.5;
}
.recurly .payment_method.multiple .payment_option:hover .logo,
.recurly .payment_method.multiple .payment_option.selected .logo,
.recurly .payment_method.multiple .payment_option:hover .accepted_cards,
.recurly .payment_method.multiple .payment_option.selected .accepted_cards {
  cursor: pointer;
  opacity: 1;
}
.recurly .payment_method .payment_option {
  line-height: 32px;
  display: block;
  height: 32px;
  line-height: 34px;
}
.recurly .payment_method .payment_option .title {
  margin: 0;
  font-size: 12px;
}
.recurly .payment_method .payment_option .icon {
  float: left;
  width: 24px;
  height: 34px;
  margin: 0 5px;
}
.recurly .payment_method .payment_option.card_option {
  border-radius: 5px 0 0 5px;
  border-right: none;
}
.recurly .payment_method .payment_option.card_option .icon {
  width: 42px;
  background: url("recurly/credit_cards/generic.png") no-repeat 0 center;
}
.recurly .payment_method .payment_option.paypal_option {
  border-radius: 0 5px 5px 0;
  float: right;
}
.recurly .payment_method .payment_option.paypal_option .logo {
  height: 24px;
  width: 90px;
  display: inline-block;
  vertical-align: middle;
  background: url("recurly/paypal_logo.png") no-repeat 0 center;
}
.recurly .paypal_message {
  width: 300px;
  font-style: italic;
  margin-bottom: 10px;
}
.recurly .contact_info {
  background: url("recurly/dash.png") repeat-x 1px bottom;
}
.recurly .accept_tos {
  background: url("recurly/dash.png") repeat-x 1px top;
  overflow: visible;
}
.recurly .accept_tos input[type=checkbox] {
  display: inline;
  line-height: 34px;
  vertical-align: middle;
}
.recurly .accept_tos label {
  margin: 0 0 0 5px;
  display: inline;
  line-height: 34px;
  vertical-align: middle;
}
.recurly .accept_tos .field .error {
  display: block;
  position: static;
}
.recurly .field {
  display: inline;
  float: left;
  clear: left;
  width: 300px;
  height: 34px;
  margin-bottom: 20px;
  position: relative;
}
.recurly .field input[type=text] {
  width: 288px;
}
.recurly .field.company_name {
  margin-bottom: 0;
}
.recurly .field .error {
  min-width: 128px;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 20px;
  z-index: 1337;
  z-index: 1;
}
.recurly .field .placeholder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-left: 9px;
  font-size: 16px;
  font-weight: normal;
  line-height: 34px;
  vertical-align: middle;
  color: #999;
  cursor: text;
  overflow: hidden;
  white-space: nowrap;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  font-weight: 300;
}
.recurly .field.focus .placeholder {
  color: #ccc;
}
.recurly .field.invalid .placeholder {
  color: #a77;
}
.recurly .field.coupon_code {
  width: 140px;
}
.recurly .field.coupon_code input[type=text] {
  width: 128px;
}
.recurly .field.first_name {
  clear: left;
  width: 140px;
}
.recurly .field.first_name input[type=text] {
  width: 128px;
}
.recurly .field.first_name .error {
  left: 300px;
}
.recurly .field.last_name {
  width: 140px;
  margin-left: 20px;
  margin-left: 20px;
  clear: none;
  clear: none;
}
.recurly .field.last_name input[type=text] {
  width: 128px;
}
.recurly .field.card_number {
  width: 220px;
}
.recurly .field.card_number input[type=text] {
  width: 208px;
}
.recurly .field.card_number .error {
  left: 300px;
}
.recurly .field.cvv {
  width: 60px;
  margin-left: 20px;
  margin-left: 20px;
  clear: none;
  clear: none;
}
.recurly .field.cvv input[type=text] {
  width: 48px;
}
.recurly .field.expires {
  width: 300px;
}
.recurly .field.expires input[type=text] {
  width: 288px;
}
.recurly .field.expires .title {
  float: left;
  font-size: 13px;
  line-height: 24px;
  vertical-align: middle;
  width: 59px;
}
.recurly .field.expires .month {
  float: left;
/* 
  width: 120px;
 */
  margin-left: 0;
}
.recurly .field.expires .month input[type=text] {
  width: 108px;
}
.recurly .field.expires .year {
  float: left;
  margin-left: 5px;
/*   width: 60px; */
}
.recurly .field.expires .year input[type=text] {
  width: 48px;
}
.recurly .field.state {
  width: 180px;
}
.recurly .field.state input[type=text] {
  width: 168px;
}
.recurly .field.state .error {
  left: 300px;
}
.recurly .field.zip {
  width: 100px;
  margin-left: 20px;
  margin-left: 20px;
  clear: none;
  clear: none;
}
.recurly .field.zip input[type=text] {
  width: 88px;
}
.recurly .field.vat_number {
  width: 140px;
  display: none;
}
.recurly .field.vat_number input[type=text] {
  width: 128px;
}
.recurly .field.vat_number.applicable {
  display: block;
}
.recurly .only_zipstreet .zip.field,
.recurly .only_zip .zip.field {
  margin-left: 0;
  clear: left;
}
.recurly .accepted_cards {
  display: inline-block;
  height: 34px;
  overflow: hidden;
}
.recurly .card {
  background-position: right top;
  background-repeat: no-repeat;
  text-indent: -3000px;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  display: inline-block;
}
.recurly .card.mastercard {
  background-image: url("recurly/credit_cards/mastercard.png");
}
.recurly .card.american_express {
  background-image: url("recurly/credit_cards/american_express.png");
}
.recurly .card.visa {
  background-image: url("recurly/credit_cards/visa.png");
}
.recurly .card.discover {
  background-image: url("recurly/credit_cards/discover.png");
}
.recurly .card.jcb {
  background-image: url("recurly/credit_cards/jcb.png");
}
.recurly .card.laser {
  background-image: url("recurly/credit_cards/laser.png");
}
.recurly .card.diners_club {
  background-image: url("recurly/credit_cards/diners_club.png");
}
.recurly .card.maestro {
  background-image: url("recurly/credit_cards/maestro.png");
}
.recurly .card.no_match {
  opacity: 0.5;
}
.recurly input[type=text],
.recurly select {
  vertical-align: middle;
  color: #000;
}
.recurly input[type=text].invalid,
.recurly select.invalid {
  border-color: #a55;
  background: #fee;
  color: #311;
}
.recurly input[type=text] {
  display: block;
  background: #fff;
  border: 1px solid #a0a0a5;
  box-shadow: inset 0 2px 3px rgba(0,0,0,0.10);
  font-family: inherit;
  padding: 5px;
/* 
  font-size: 16px;
  height: 22px;
 */
}
.recurly input[type=text][disabled] {
  background: #eee;
}
.recurly input[type=checkbox] {
  color: #f00;
}
.recurly select {
  color: inherit;
  font-family: inherit;
  width: 100%;
}
.recurly select > option {
  color: inherit;
}
.recurly .due_now {
  background: url("recurly/due_now.png") no-repeat top left;
  clear: both;
  color: #2a3a3c;
  height: 70px;
  line-height: 67px;
  vertical-align: middle;
  padding: 0 25px;
  width: 460px;
  max-width: 100%;
  position: relative;
  left: -5px;
  text-shadow: 0 1px 0 rgba(255,255,255,0.50);
}
.recurly .due_now .title {
  float: left;
  font-size: 29px;
  position: relative;
}
.recurly .due_now .cost {
  color: #fff;
  float: right;
  font-size: 33px;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0;
  position: relative;
  text-shadow: 0px 1px 1px rgba(0,0,0,0.90);
}
.recurly .footer {
  border-radius: 0px 0px 9px 9px;
  margin: 0px;
  padding: 20px;
}
.recurly.submitting .footer {
  background: url("recurly/submitting.gif") no-repeat 180px 28px;
}
.recurly button.submit {
  height: 46px;
  max-width: 600px;
  font-size: 18px;
  font-weight: 700;
  color: #302106;
  text-align: center;
  margin-left: 0px;
  border: 1px solid #767674;
  background: #e7a500;
  border-radius: 10px;
  outline: none;
  box-shadow: inset rgba(255,255,255,0.70) 0px 1px 0px, rgba(0,0,0,0.50) 0px 1px 3px;
/* 
  background-image: -webkit-gradient(linear, 0% 20%, 0% 100%, from(#fecd00), to(#ce7b00));
  background: -moz-linear-gradient(top, #fecd00, #ce7b00);
 */
  text-shadow: rgba(255,255,255,0.50) 0 1px 0;
  padding: 10px 20px;
}
.recurly button.submit:hover {
  color: #451;
}
.recurly button.submit:active {
  top: 2px;
  color: #302106;
  text-shadow: rgba(255,255,255,0.57) 0 -1px 0;
  outline: none;
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#ce7b00), to(#fecd00));
  background: -moz-linear-gradient(top, #ce7b00, #fecd00);
  box-shadow: rgba(255,255,255,0.69) 0px -1px 0px inset, rgba(0,0,0,0.26) 0px 2px 3px;
}
.recurly button.submit[disabled] {
  position: relative;
  height: 46px;
  max-width: 600px;
  padding: 0 10px;
  font-weight: 700;
  color: #555;
  text-shadow: rgba(255,255,255,0.57) 0 1px 0;
  text-align: center;
  opacity: 0.75;
  border: 1px solid #767674;
  background: #e7a500;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  outline: none;
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#dbd9d2), to(#999));
  background: -moz-linear-gradient(top, #dbd9d2, #999);
  -webkit-background-clip: padding-box;
  -webkit-box-shadow: rgba(255,255,255,0.69) 0px 1px 0px inset, rgba(0,0,0,0.26) 0px 2px 3px;
  box-shadow: rgba(255,255,255,0.70) 0px 1px 0px inset, rgba(0,0,0,0.27) 0px 2px 3px;
}
.iefail {
  background: #666;
  padding: 10px;
  position: absolute;
  top: -1%;
  left: -1%;
  height: 102%;
  width: 102%;
  z-index: 9999;
}
.iefail .chromeframe {
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
}
.iefail .chromeframe p {
  text-align: center;
}
.iefail .chromeframe p.blast {
  font-size: 1.3em;
  font-weight: bold;
}
.iefail .chromeframe p a {
  color: #4183c4;
  text-transform: capitalize;
}
