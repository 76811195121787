@font-face {
    font-family: 'Hero';
    src: url('public/fonts/hero_light-webfont.eot');
    src: url('public/fonts/hero_light-webfont.eot?#iefix') format('embedded-opentype'),
         url('public/fonts/hero_light-webfont.woff2') format('woff2'),
         url('public/fonts/hero_light-webfont.woff') format('woff'),
         url('public/fonts/hero_light-webfont.ttf') format('truetype'),
         url('public/fonts/hero_light-webfont.svg#hero_lightlight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Hero';
    src: url('public/fonts/hero-webfont.eot');
    src: url('public/fonts/hero-webfont.eot?#iefix') format('embedded-opentype'),
         url('public/fonts/hero-webfont.woff2') format('woff2'),
         url('public/fonts/hero-webfont.woff') format('woff'),
         url('public/fonts/hero-webfont.ttf') format('truetype'),
         url('public/fonts/hero-webfont.svg#heroregular') format('svg');
    font-weight: bold;
    font-style: normal;
}


/// Mixin to place items on a circle
/// @author Hugo Giraudel
/// @author Ana Tudor
/// @param {Integer} $item-count - Number of items on the circle
/// @param {Length} $circle-size - Large circle size
/// @param {Length} $item-size - Single item size

@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  width:  $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%; 
  list-style: none;       

  $enlarged: $item-size + 2;
  
  
  > * {
    display: block;
    position: absolute;
    top:  50%; 
    left: 50%;
    width:  $item-size;
    height: $item-size;
    margin: -($item-size / 2);

    $angle: (360 / $item-count);
    $rot: -25;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: 
          rotate($rot * 1deg) 
          translate($circle-size / 2) 
          rotate($rot * -1deg);
      }

      $rot: $rot + $angle;
    }
  }

  img {
	width: $item-size;
	height: $item-size;
	margin:0px auto;
	-webkit-transition: all .15s; 
	transition: all .15s;
	transition-timing-function: ease;
  }


  > li {
	
	text-align:center;
	-webkit-transition: all .15s; 
	transition: all .15s;
	transition-timing-function: ease;
	
	&:hover,
	&:active {
	  width: $enlarged;
	  height: $enlarged;
	  margin: -($enlarged / 2);
	  
	  img {
		width: $enlarged;
		height: $enlarged;
		-webkit-transition: all .15s; 
		transition: all .15s;
		transition-timing-function: ease;
	  }
	  
	}
  }

}


.circle-container {
  @include on-circle($item-count: 5, $circle-size: 20em, $item-size: 150px); 
  margin: 2em auto 2em;
  border: solid 5px tomato;

	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;         /* Opera/IE 8+ */	
  
  
  img { 
    display: block; 
/* 
    max-width: 100%; 
 */
/* 
    border-radius: 50%;
    border: solid 5px tomato;
    border:none;
 */
/* 
    transition: .15s;
 */
    filter: grayscale(50%);

    -webkit-transition: all .15s; 
    transition: all .15s;
    transition-timing-function: ease;
    
    
    &:hover,
    &:active {
      filter: grayscale(0);
    }
  }

}



// $accent-blue:	#3163ac !default;

$accent-orange:	#cc1616 !default;
$accent-purple:	#AD1457 !default;
$accent-teal:	#3a9b92 !default;
$accent-black:	#333 !default;
$accent-blue:	#045FA4 !default;
$accent-red:	#c10802 !default;

// $accent-green:	#7bb94e !default;
// $accent-gold:	#f4b800 !default;


.btn-default {

}




h1, h2, h3 {
	font-family: 'Hero';
	font-family: 'Lato';
}
html, body, p {
	font-family: 'Hero';
	font-family: 'Lato';
	line-height: 1.25em;
}

footer {text-align:center;font-size: .75em;margin-top:3em;margin-bottom:2em;color: #999;}
footer a {color: #666 !important;margin: 0em .25em 0em;}
footer a:hover {color: #999 !important; text-decoration:none;}
footer .legal {display:block;margin-top: .5em;font-size: .95em;}

.gray footer {color: #aaa;}
.gray footer a {color: #bbb !important;}
.gray footer a:hover {color: #ddd !important;}
.gray footer .legal {color: #999;}


/* 
.navbar.navbar-default {
	position: absolute;
	width:100%;
}
 */

.navbar-default {
    -webkit-transition: all .25s; /* For Safari 3.1 to 6.0 */
    transition: all .25s;
    transition-timing-function: ease;
    border: none;
}
.navbar-nav > li > a {line-height: 40px;padding-top:15px;padding-bottom:15px;}


.navbar-default {background: fade-out(black,.5);}
.navbar-default.scroll { background: $accent-blue; border-bottom: 1px solid darken($accent-blue, 2.5%);}
.navbar-default .navbar-nav > li > a { color: white; }
.navbar-default .navbar-nav > li > a:hover { color: #eee;}


body.gray {background-color: #666;color:#fff;}
.gray a, .gray #intro h1 {color:#fff;}

.pageHome #intro h1 {font-size:3.5em;font-size:5.25vw;margin: 1em auto .25em;}
#intro h2 {font-size:3.5em;font-size:4.5vw;margin: 1.5em auto .25em;}
#intro p {font-size:1.25em;font-size:2.15vw;color:#aaa;margin-bottom: 3em;}

.pageServices header, .pageProjects header {margin-bottom: 3em;}
.pageServices header p, .pageProjects header p {color:#ccc;font-size: 1.25em;margin-bottom: 2.5em;}



.gray .navbar-default { background: transparent; }
.gray .navbar-default.scroll { background: fade-out(#666,.15); border-bottom: 1px solid darken(fade-out(#666,.15), 2.5%);}
.gray .navbar-default .navbar-nav > li > a { color: #fff; }
.gray .navbar-default .navbar-nav > li > a:hover { color: #eee;}

/*
.gray .navbar-default.scroll .navbar-brand img {background-position: 0% 0%;}
.gray .navbar-default.scroll .navbar-nav > li > a { color: white; }
.gray .navbar-default.scroll .navbar-nav > li > a:hover { color: #eee;}
*/

.white .navbar-default { background: transparent; }
.white .navbar-default.scroll { background: fade-out(#333,.15); border-bottom: 1px solid darken(fade-out(#333,.15), 2.5%);}
.white .navbar-default .navbar-nav > li > a { color: #999; }
.white .navbar-default .navbar-nav > li > a:hover { color: #333;} /* 99c4ea */

.white .navbar-default.scroll .navbar-brand img {background-position: 0% 0%;}
.white .navbar-default.scroll .navbar-nav > li > a { color: white; }
.white .navbar-default.scroll .navbar-nav > li > a:hover { color: #eee;} /* 99c4ea */

.pageWebsites .navbar-default {background: fade-out(#8C1919,.5);}
.pageWebsites .navbar-default.scroll { background: #8C1919; border-bottom: 1px solid darken(#8C1919, 2.5%);}
.pageWebsites .navbar-default .navbar-nav > li > a { color: white; }
.pageWebsites .navbar-default .navbar-nav > li > a:hover { color: #eee;}

.pageHosting .navbar-default {background: fade-out(#851445,.5);}
.pageHosting .navbar-default.scroll { background: #851445; border-bottom: 1px solid darken(#851445, 2.5%);}
.pageHosting .navbar-default .navbar-nav > li > a { color: white; }
.pageHosting .navbar-default .navbar-nav > li > a:hover { color: #eee;}

.pageGlideStep .navbar-default {background: fade-out(#237A72,.5);}
.pageGlideStep .navbar-default.scroll { background: #237A72; border-bottom: 1px solid darken(#237A72, 2.5%);}
.pageGlideStep .navbar-default .navbar-nav > li > a { color: white; }
.pageGlideStep .navbar-default .navbar-nav > li > a:hover { color: #eee;}

.pageSupport .navbar-default {background: fade-out(#171717,.5);}
.pageSupport .navbar-default.scroll { background: #171717; border-bottom: 1px solid darken(#171717, 2.5%);}
.pageSupport .navbar-default .navbar-nav > li > a { color: white; }
.pageSupport .navbar-default .navbar-nav > li > a:hover { color: #eee;}

.pageSoundBoard .navbar-default {background: fade-out(#0F5991,.5);}
.pageSoundBoard .navbar-default.scroll { background: #0F5991; border-bottom: 1px solid darken(#0F5991, 2.5%);}
.pageSoundBoard .navbar-default .navbar-nav > li > a { color: white; }
.pageSoundBoard .navbar-default .navbar-nav > li > a:hover { color: #eee;}

.pageGroundBridge .navbar-default {background: fade-out(#7D1815,.5);}
.pageGroundBridge .navbar-default.scroll { background: #7D1815; border-bottom: 1px solid darken(#7D1815, 2.5%);}
.pageGroundBridge .navbar-default .navbar-nav > li > a { color: white; }
.pageGroundBridge .navbar-default .navbar-nav > li > a:hover { color: #eee;}

.pageSteamHook .navbar-default {background: fade-out(#46331c,.5);}
.pageSteamHook .navbar-default.scroll { background: #46331c; border-bottom: 1px solid darken(#46331c, 2.5%);}
.pageSteamHook .navbar-default .navbar-nav > li > a { color: white; }
.pageSteamHook .navbar-default .navbar-nav > li > a:hover { color: #eee;}

.pageBoxKite .navbar-default {background: fade-out(#2b2240,.5);}
.pageBoxKite .navbar-default.scroll { background: #2b2240; border-bottom: 1px solid darken(#2b2240, 2.5%);}
.pageBoxKite .navbar-default .navbar-nav > li > a { color: white; }
.pageBoxKite .navbar-default .navbar-nav > li > a:hover { color: #eee;}


.pageMangedWebsites .navbar-default {background: fade-out(#33845c,.5);}
.pageMangedWebsites .navbar-default.scroll { background: #33845c; border-bottom: 1px solid darken(#33845c, 2.5%);}
.pageMangedWebsites .navbar-default .navbar-nav > li > a { color: white; }
.pageMangedWebsites .navbar-default .navbar-nav > li > a:hover { color: #eee;}

.pageLeadCampaigns .navbar-default {background: fade-out(#8a6d3b,.5);}
.pageLeadCampaigns .navbar-default.scroll { background: #8a6d3b; border-bottom: 1px solid darken(#8a6d3b, 2.5%);}
.pageLeadCampaigns .navbar-default .navbar-nav > li > a { color: white; }
.pageLeadCampaigns .navbar-default .navbar-nav > li > a:hover { color: #eee;}


.navbar-default, .navbar-default.scroll {border-bottom:none !important;}
.navbar-default .navbar-toggle {margin-top:17px; background-color: transparent !important;}
.navbar-default .navbar-toggle .icon-bar {background-color:#eee;}
.white .navbar-default .navbar-toggle .icon-bar {background-color:#999;}


/* 
.navbar-default.scroll .navbar-nav > li > a {
	color: inherit;
}
.navbar-default.scroll .navbar-nav > li > a:hover {
	color: inherit;
}
 */

.navbar-brand {
	height: auto;
	padding: 10px;
}
.navbar-brand img {
/* 
	max-height:50px !important;
 */
}

.navbar-brand img {
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: url('ppc-logo.svg') no-repeat 0px 0px;
  background-size: 200%;
  width: 200px; /* Width of new image */
  height: 50px; /* Height of new image */
  padding-left: 200px; /* Equal to width of new image */
}

.pageWebsites .navbar-brand img {background-position: 0% 20%;}
.pageHosting .navbar-brand img {background-position: 0% 40%;}
.pageGlideStep .navbar-brand img {background-position: 0% 60%;}
.pageSupport .navbar-brand img {background-position: 0% 0%;}
.pageSoundBoard .navbar-brand img {background-position: 0% 80%;}
.pageGroundBridge .navbar-brand img {background-position: 0% 100%;}
.pageSteamHook .navbar-brand img {background-position: 100% 0%;}
.pageBoxKite .navbar-brand img {background-position: 100% 20%;}

.pageMangedWebsites .navbar-brand img {background-position: 100% 20%;}
.pageLeadCampaigns .navbar-brand img {background-position: 00% 40%;}

.white .navbar-brand img {background-position: 100% 40%;}


/* 
.navbar-default.scroll .navbar-brand img {
	background-position: 0px -50px;
}
 */


/* 
.dropdown-menu {
    display: none;
}

.open>.dropdown-menu {
	display:block;
}
 */
 

.fulltop {
	background: $brand-primary;
	color:white;
	position: relative;
	min-height: 100vh;
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;         /* Opera/IE 8+ */	
}
.bigtop {
	background: $brand-primary;
	color:white;
	position: relative;
/* 
	min-height: 80vh;
 */
	-webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
	-moz-box-sizing: border-box;    /* Firefox, other Gecko */
	box-sizing: border-box;         /* Opera/IE 8+ */	
	border: 0px solid blue;
}

.bigtop {
  background: #dfdce3;
  background-image: linear-gradient(-70deg, #111, #2D2D2D, #333);
  color: white;
  position: relative;
  /* 
	min-height: 80vh;
 */
  /* Safari/Chrome, other WebKit */
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
  border: 0px solid blue; }

.bigtop p {
  margin-bottom: 1.5em; }


.pageHome .bigtop {
  background: linear-gradient(-90deg, #212121, #37474F, #263238);
}
.pageWebsites .bigtop {
  background-image: linear-gradient(-70deg, #ffeb80, #f39230, #cc1616);
}
.pageHosting .bigtop {
  background-image: linear-gradient(-70deg, #7986CB, #C2185B, #AD1457);
}
.pageGlideStep .bigtop {
  background-image: linear-gradient(-70deg, #b7c558, #53ddd0, #3a9b92);
}
.pageSupport .bigtop {
  background-image: linear-gradient(-70deg, #111111, #2D2D2D, #333333);
}
.pageSoundBoard .bigtop {
  background-image: linear-gradient(-70deg, #48d6d1, #2196F3, #045FA4);
}
.pageGroundBridge .bigtop {
  background-image: linear-gradient(-70deg, #CCAB29, #c10802, #941815);
}
.pageSteamHook .bigtop {
  background-image: linear-gradient(-70deg, #a3613d, #a3814c, #573e21);
}
.pageBoxKite .bigtop {
  background-image: linear-gradient(-70deg, #6e4507, #0e2c52, #a13437);
}
.pageMangedWebsites .bigtop {
  background-image: linear-gradient(-70deg, #92df50, #9fbe42, #33845c);
}
.pageLeadCampaigns .bigtop {
  background-image: linear-gradient(-70deg, #eab973, #8a6d3b);
}


.btn-primary {
	background-color: #045FA4;
	border-color: #045FA4;
}

.bigtop a.btn-default, .bigtop a.btn-primary {margin: 0em 1.25em .75em 0em;font-size:.9em;padding:.5em .75em; text-transform:uppercase;}
.bigtop a.btn-default {background-color: transparent;border-color:white; color:white;}
.bigtop a.btn-default:hover {background-color: transparent;border-color:#eee; color:#eee;}

.pageWebsites .btn-primary {background-color: #8C1919;border-color:#8C1919;}
.pageWebsites .bigtop .btn-primary {background-color: invert(#cc1616);border-color:invert(#cc1616);color:#333;}

.pageHosting .btn-primary {background-color: #851445;border-color:#851445;}
.pageHosting .bigtop .btn-primary {background-color: invert(#AD1457);border-color:invert(#AD1457);color:#333;}

.pageGlideStep .btn-primary {background-color: #237A72;border-color:#237A72;}
.pageGlideStep .bigtop .btn-primary {background-color: invert(#3a9b92);border-color:invert(#3a9b92);}

.pageSupport .btn-primary {background-color: red;border-color:red;}
.pageSupport .bigtop .btn-primary {background-color: red;border-color:red;}

.pageSoundBoard .btn-primary {background-color: red;border-color:red;}
.pageSoundBoard .bigtop .btn-primary {background-color: invert(#045FA4);border-color:invert(#045FA4);color:#333;}

.pageGroundBridge .btn-primary {background-color: red;border-color:red;}
.pageGroundBridge .bigtop .btn-primary {background-color: #FFD942;border-color:#FFD942;color:#333;}

.pageSteamHook .btn-primary {background-color: red;border-color:red;}
.pageSteamHook .bigtop .btn-primary {background-color: invert(#045FA4);border-color:invert(#045FA4);}

.pageBoxKite .btn-primary {background-color: red;border-color:red;}
.pageBoxKite .bigtop .btn-primary {background-color: invert(#a13437);border-color:invert(#a13437);}

.pageMangedWebsites .btn-primary {background-color: red;border-color:red;}
.pageMangedWebsites .bigtop .btn-primary {background-color: #e2574c;border-color:#e2574c;}

.pageLeadCampaigns .btn-primary {background-color: red;border-color:red;}
.pageLeadCampaigns .bigtop .btn-primary {background-color: #9faeea;border-color:#9faeea;}


.pageLeadCampaigns #features .nav-tabs li {width: 25%;}
.pageLeadCampaigns #features .nav-tabs li a {color: fade-out(#8a6d3b,.5) !important;background-color: fade-out(#8a6d3b,.75);}
.pageLeadCampaigns #features .nav-tabs li.active a {color: #8a6d3b !important;background-color: #f5f8fa;}

.bot {background: #666;}
.pageWebsites .bot {background: #f39230;}
.pageHosting .bot {background: #AD1457;}
.pageGlideStep .bot {background: #3a9b92;}
.pageSupport .bot {background: #333;}
.pageSoundBoard .bot {background: #2196F3;}
.pageGroundBridge .bot {background: #c10802;}
.pageSteamHook .bot {background: #573e21;}
.pageBoxKite .bot {background: #313e56;}
.pageMangedWebsites .bot {background: #33845c;}
.pageLeadCampaigns .bot {background: #8a6d3b;}


#clients {background: #eee;}
.pageWebsites #clients {background: fade-out(#f39230,.9);}
.pageHosting #clients {background: fade-out(#AD1457,.9);}
.pageGlideStep #clients {background: fade-out(#3a9b92,.9);}
.pageSupport #clients {background: fade-out(#333,.9);}

.pageMangedWebsites #clients {background: fade-out(#33845c,.9);}
.pageLeadCampaigns #clients {background: fade-out(#8a6d3b,.9);}



.numberCircle {background: #fff;color: #666;border: 2px solid #666;}
.pageWebsites .numberCircle {background: #fff;color: #cc1616;border: 2px solid #f39230;}
.pageHosting .numberCircle {background: #fff;color: #AD1457;border: 2px solid #AD1457;}
.pageGlideStep .numberCircle {background: #fff;color: #3a9b92;border: 2px solid #3a9b92;}
.pageSupport .numberCircle {background: #fff;color: #666;border: 2px solid #666;}



#setups.service a {background-image: linear-gradient(-70deg, #f39230, #cc1616);}
#hosting.service a {background-image: linear-gradient(-70deg, #C2185B, #AD1457);}
#glidestep.service a {background-image: linear-gradient(-70deg, #53ddd0, #3a9b92);}
#support.service a {background-image: linear-gradient(-70deg, #2D2D2D, #333);}
#soundboard.service a {background-image: linear-gradient(-70deg, #2196F3, #045FA4);}
#groundbridge.service a {background-image: linear-gradient(-70deg, #ff4c48, #a00702);}
#steamhook.service a {background-image: linear-gradient(-70deg, #a3814c, #573e21);}
#boxkite.service a {background-image: linear-gradient(-70deg, #6e4507, #0e2c52, #a13437);}



#audit .report {
margin-bottom: 0em;
}

#audit .report .labels, #audit .report .data .row div {
	font-size: 1.25em;
	padding: .85em .5em;
}
#audit .report .data .row i {
	font-size: 1.25em;
}

#audit .report .data .green {
	color: green;
}
#audit .report .data .red {
	color: red;
}
#audit .report .data .gold {
	color: #fcb750;
}

.urlName {
/* 	overflow-y: hidden; */
	height: 10em;
	margin-top: 0em;
	margin-bottom: 1em;
}
#audit .report .urlName.focus {
	background-color: transparent;
}

/* 
.urlName:before {
  content:'';
  width:100%;
  height:100%;    
  position:absolute;
  left:0;
  top:0;
  background:linear-gradient(#f5f8fa, transparent 80px);
}
 */

.urlName span {
	transform: rotate(-55deg);
	transform-origin: left top 0;
	float: left;
	font-size: .85em;
	position: absolute;
	bottom: 0;
	left: 20%;
/* 	z-index: -9999; */
}

.labels a {cursor:pointer;text-decoration:none;}
.labels i {opacity: .75;font-size: .875em;margin-left: .75em;display:inline-block;}

#audit .report .focus {
background-color: $gray-lighter;
}


#audit h3 {
font-size: 1.15em;margin-bottom: .5em;font-weight: bold;
}
#audit .resources ul {
padding-left:0em;
font-size: .85em;
}
#audit .resources li {
list-style:none;
margin-left:0em;
margin-bottom:.25em;
}


#audit #summary {
	background-color: $gray-lighter;
	padding-top: 1em;
	margin-top: 0em;
	margin-bottom: 3em;
}

#cta_blocks {
	font-size: .85em;
}

#cta_blocks a.btn {
	margin: .5em auto 1em;
	display: inline-block;
	width: auto;
	padding: .5em;
	font-size: 1em;
	text-transform: uppercase;
	float:right;
	display:inline-block;
	clear:none;
}

#attribution {
	font-size: .75em;
	line-height: 1.25em;
	margin: 4em auto;
	opacity: .85;
}

#attribution .logos a {
	margin: .75em;
	display:inline-block;
}
#attribution .logos a img {
	height:auto !important;max-width: 80px;opacity: .85;
}

.modal-footer .btn-default {
	width: 100%;
}


.block {
	display: inline-block;
	background-color: fade-out($accent-purple,.25);
	padding: 1em;
	max-width: 500px;
}

.topFooter {
	position:absolute;
	bottom:0;left:0;
	width: 100%;
	background: rgba(0,0,0,.5);
	background: fade-out($accent-blue,.25);
	padding:2em;

    -webkit-transition: all .25s; /* For Safari 3.1 to 6.0 */
    transition: all .25s;
    transition-timing-function: ease;

}
.topFooter.scroll {
	background: fade-out(#333,.05);
}
.topFooter h1 {
	text-transform: uppercase;
	margin:0em auto 0em;
	font-size: 2em;
}
.topFooter a {
	margin-top: 0em;
}

/*
.red .block {
	background-color: fade-out($accent-red,.5);
}
.blue .block {
	background-color: fade-out($accent-blue,.25);
}
.green .block {
	background-color: fade-out($accent-green,.5);
}
*/



/*
FOR FIXED NAVBAR, CREATES TOP SPACE
*/


.main:before {
	content: '';
	display:block;
	width: 100%;
	height: ($navbar-ppc-height + $navbar-margin-bottom); 
	z-index: -9999;
}

.fulltop, .bigtop {
	top: -($navbar-ppc-height + $navbar-margin-bottom);
	margin-bottom: -($navbar-ppc-height + $navbar-margin-bottom);
	padding-top: ($navbar-ppc-height + $navbar-margin-bottom + 40px);
	padding-bottom: 50px;
}


.spotlight {
  background-color: #eee;
  padding: 3em 0em;
  font-size: 1.15em; }
.spotlight h3 {
  font-size: 1.5em;
  margin-top: 0em; }

#clients {
  padding: 3em 0em;
  text-transform: uppercase;
  font-size: 1em; }

.spotlight.inverse {
  background-color: #999;
  color: white; }

.spotlight.red {
  background-color: #e1002a;
  color: white; }

.spotlight.blue {
  background-color: #2196F3;
  color: white; }

.spotlight.green {
  background-color: #7bb94e;
  color: white; }

.spotlight.gold {
  background-color: #f4b800;
  color: white; }

.spotlight.featureA {
  background-color: #C4C3C3;
  color: #2D2D2D; }

.spotlight.featureB {
  background-color: #2D2D2D;
  color: #C4C3C3; }

.spotlight.featureC {
  background-color: #949494;
  color: white; }

.spotlight.featureD {
  background-color: #C4C3C3;
  color: #2D2D2D; }

.spotlight a {font-weight: bold;}

.featureA a {color: #2D2D2D;}
.featureB a {color: #C4C3C3;}
.featureC a {color: white;}
.featureD a {color: #2D2D2D;}

.spotlight.gradient {
  background: linear-gradient(to bottom right, red, yellow);
  background: linear-gradient(-90deg, #9FA8DA, #D81B60);
  background: linear-gradient(-90deg, #7986CB, #C2185B, #AD1457);
  color: white; }

/*

http://paletton.com/#uid=33h0u0kXOPmqXV5DnX5XnuRW0nY
http://paletton.com/#uid=7390u0kXOPmqXV5DnX5XnuRW0nY
http://paletton.com/#uid=70T0u0kXOPmqXV5DnX5XnuRW0nY

*/


#features h2 {
	font-size: 2.5em;
	margin: 1.75em auto .25em;
}
#features h3 {
	font-size: 1.5em;
	margin-bottom: .15em;
}
#features .des {
	font-size: .95em;
}
#features .miniHeader {
	margin: 0em auto 2em;
}	

.nav-tabs li {width: 14.25%;font-size: .75em;text-align:center;text-transform:uppercase;}
.nav-tabs li a {padding: 1.5em 0em 1em;}
.nav-tabs .fa-stack {font-size: 2.5em;margin-bottom: .25em;}

.nav-tabs li a {color: #999;background-color: #ddd;}
.nav-tabs li a .fa-inverse {color: #eee;}
.nav-tabs li.active a {color: #3097D1 !important;}
.nav-tabs li.active a .fa-inverse {color: #fff;}

.tab-pane {padding-bottom: 6em;}

#pricing {
background-color: #666;
color: #fff;
}

.pricingTable {margin-top: 2em;margin-bottom: 2em;}

.levels {font-size: 1em;font-weight: bold;text-transform: uppercase;}
.budget {font-size: 1.15em;padding: 1.25em 0em;}
.section {font-size: .9em;font-weight: bold;text-transform: uppercase;padding-top: 1em;padding-bottom: .5em;opacity: .5;}
.service {font-size: .9em;font-weight: normal;}
.pricingTable .low, .pricingTable .mid, .pricingTable .high {text-align:center;}
.posts, .frequency, .hours, .per {font-size: .75em; opacity: .75;text-transform: capitalize;}
.posts, .frequency {font-weight: bold;}
.per {text-transform: lowercase;font-size: .675em;margin-right: -1.25em;}

.service, .includes {padding-top: .125em;padding-bottom: .125em;}

/* 
.middle {
	position: relative;
	margin-top: ($navbar-ppc-height + $navbar-margin-bottom + 40px);
}
 */

.exploding_guts { display:none !important; }


.ico::after {
	content: '';
	height: 0;
	width: 100%;
	padding-bottom: 100%;
	margin: 1.5em 10% 1em;
	background-image: url("benefits.svg");
	background-position: 0 0;
	background-size: 1100%;
	display:block;
}

/* 100% / (number_of_images – 1) */


/* SETUPS */
#responsive-design .ico::after {background-position: 0% 0%;}
#gravity-configuration .ico::after {background-position: 10% 0%;}
#wordpress-base .ico::after {background-position: 20% 0%;}
#keyword-research .ico::after {background-position: 30% 0%;}
#local-seo .ico::after {background-position: 40% 0%;}
#competitive-analysis .ico::after {background-position: 50% 0%;}
#analytic-goals .ico::after {background-position: 60% 0%;}
#seo-foundations .ico::after {background-position: 70% 0%;}
#url-migration .ico::after {background-position: 80% 0%;}
#copywriting-support .ico::after {background-position: 90% 0%;}
#cloudflare-setup .ico::after {background-position: 100% 0%;}
#ssl-setup .ico::after {background-position: 0% 20%;}
#social-media-guide .ico::after {background-position: 10% 20%;}
#media-support .ico::after {background-position: 20% 20%;}
#newsletter-support .ico::after {background-position: 30% 20%;}
#glidestep-trial .ico::after {background-position: 40% 20%;}

#clean-design .ico::after {background-position: 80% 20%;}
#visual-composer .ico::after {background-position: 50% 20%;}


/* HOSTING */
#wordpress-updates .ico::after {background-position: 0% 40%;}
#ample-bandwidth .ico::after {background-position: 10% 40%;}
#domain-management .ico::after {background-position: 20% 40%;}
#uptime-monitoring .ico::after {background-position: 30% 40%;}
#daily-backups .ico::after {background-position: 40% 40%;}
#malware-scanning .ico::after {background-position: 50% 40%;}
#cloudflare-service .ico::after {background-position: 60% 40%;}
#dns-management .ico::after {background-position: 70% 40%;}
#mandrill-service .ico::after {background-position: 80% 40%;}
#human-webmaster .ico::after {background-position: 90% 40%;}

/* GLIDESTEP */
#measure-progress .ico::after {background-position: 0% 60%;}
#crawl-diagnostics .ico::after {background-position: 10% 60%;}
#simplified-reporting .ico::after {background-position: 20% 60%;}
#domain-authority .ico::after {background-position: 30% 60%;}
#kpi-targets .ico::after {background-position: 40% 60%;}
#contextual-feedback .ico::after {background-position: 50% 60%;}
#social-engagement .ico::after {background-position: 60% 60%;}
#server-monitoring .ico::after {background-position: 70% 60%;}
#device-overview .ico::after {background-position: 80% 60%;}
#team-priorities .ico::after {background-position: 90% 60%;}
#expert-analysis .ico::after {background-position: 100% 60%;}
#limited-support .ico::after {background-position: 100% 40%;}
#cblock-tracking .ico::after {background-position: 100% 80%;}
#critical-alerts .ico::after {background-position: 70% 20%;}

#spam-score .ico::after {background-position: 90% 20%;} 


/* AGENCY */
#special-reports .ico::after {background-position: 0% 80%;}
#crawl-remediation .ico::after {background-position: 10% 80%;}
#publishing-support .ico::after {background-position: 20% 80%;}
#local-seo .ico::after {background-position: 30% 80%;}
#newsletter-management .ico::after {background-position: 40% 80%;}
#adwords-support .ico::after {background-position: 70% 80%;}
#link-building .ico::after {background-position: 80% 80%;}
#original-content .ico::after {background-position: 90% 80%;}


#analytics-configuration .ico::after {background-position: 60% 0%;}
#page-speeds .ico::after {background-position: 60% 40%;}

#keyword-opportunities .ico::after {background-position: 0% 80%;}

#flight-checks .ico::after {background-position: 100% 20%;} 
#social-original-content .ico::after {background-position: 80% 20%;}
#build-new-subscribers .ico::after {background-position: 20% 100%;}
#build-new-followers .ico::after {background-position: 30% 20%;}

#content-curation .ico::after {background-position: 50% 80%;}
#social-media-support .ico::after {background-position: 100% 60%;}

#topic-discovery .ico::after {background-position: 0% 60%;}
#content-meta-configuration .ico::after {background-position: 70% 0%;}

#email-automation .ico::after {background-position: 0% 100%;}
#copywriting-drafts .ico::after {background-position: 10% 100%;}
#team-interviews .ico::after {background-position: 40% 100%;}

#adwords-experiments .ico::after {background-position: 30% 100%;}

#publishing-support .ico::after {background-position: 60% 80%;}

#publishing-new-strategies .ico::after {background-position: 100% 60%;}
#adwords-landing-pages .ico::after {background-position: 20% 80%;}
#development-hotfixes .ico::after {background-position: 30% 40%;}

#linkbuilding-outreach .ico::after {background-position: 20% 40%;}
#linkbuilding-disavowal .ico::after {background-position: 50% 40%;}


#team-strategy-planning .ico::after {background-position: 50% 100%;}
#team-production-planning .ico::after {background-position: 30% 0%;}
#team-regualar-calls .ico::after {background-position: 90% 40%;}



/* GS SITES */
#branded-design .ico::after {background-position: 90% 100%;}
#rapid-turnaround .ico::after {background-position: 80% 100%;}
#lead-campaign-support .ico::after {background-position: 70% 100%;}




.logo {
	display:inline-block;
	width:30%;
	height:30%;
	margin: .5%;
}

.logo::after {
	content: '';
	height: 0;
	background-image: url("clients.png");
	background-position: 0 0;
	background-size: 600%;
	width: 100%;
	padding-bottom: 75%;
	margin: 0%;
	display:block;
	filter: saturate(.25) opacity(.9);
	filter: saturate(1) opacity(.95);
/* 
	filter: sepia(1) hue-rotate(241deg) hue-rotate(241deg);
 */
}

.pageAbout .logo::after {
	filter: saturate(1) opacity(1);
}

/* SETUPS */
#embassy-suites.logo::after {background-position: 0% 0%;}
#legacy-ventures.logo::after {background-position: 20% 0%;}
#trappeze.logo::after {background-position: 20% 45%;}
#bitzer.logo::after {background-position: 40% 15%;}
#focus-litigation.logo::after {background-position: 80% 15%;}
#ike-jane.logo::after {background-position: 0% 45%;}
#game-x.logo::after {background-position: 0% 15%;}


/* HOSTING */
#gregg-irby.logo::after {background-position: 100% 30%;}
#bonedry-roofing.logo::after {background-position: 60% 15%;}
#glenn-hotel.logo::after {background-position: 100% 0%;}
#recordpoint.logo::after {background-position: 40% 30%;}

/* SUPPORT */
#urban-santuary.logo::after {background-position: 0% 30%;}
#accountchek.logo::after {background-position: 20% 15%;}
#terry-entrepreneurship.logo::after {background-position: 60% 0%;}
#miles-mediation.logo::after {background-position: 20% 30%;}
#citrusolution.logo::after {background-position: 40% 45%;}

/* AGENCY */
#soprano-design.logo::after {background-position: 40% 0%;}
#williams-associates.logo::after {background-position: 100% 15%;}
#everlast-synthetic.logo::after {background-position: 80% 30%;}
#reynolds-golf.logo::after {background-position: 80% 0%;}
#mendez.logo::after {background-position: 60% 30%;}


.anchor {width: 100%; height: 0px; display:inline-block;opacity:0;position:relative; float: left;}


.spotlight .ico::after {
	background-image: url("spotlights.svg");
	background-size: 600%;
	width: 79%;
	padding-bottom: 49%;
	margin: .75em 10% 1em;
}

.pageWebsites .spotlight.featureA .ico::after {background-position: 0 0;}
.pageWebsites .spotlight.featureB .ico::after {background-position: 20% 0;}
.pageWebsites .spotlight.featureC .ico::after {background-position: 40% 0;}
.pageWebsites .spotlight.featureD .ico::after {background-position: 60% 0;}

.pageHosting .spotlight.featureA .ico::after {background-position: 80% 0;}
.pageHosting .spotlight.featureB .ico::after {background-position: 100% 0;}
.pageHosting .spotlight.featureC .ico::after {background-position: 0% 25%;}
.pageHosting .spotlight.featureD .ico::after {background-position: 20% 25%;}

.pageGlideStep .spotlight.featureA .ico::after {background-position: 80% 25%;}
.pageGlideStep .spotlight.featureB .ico::after {background-position: 60% 25%;}
.pageGlideStep .spotlight.featureC .ico::after {background-position: 100% 25%;}
.pageGlideStep .spotlight.featureD .ico::after {background-position: 0% 50%;}

.pageSupport .spotlight.featureA .ico::after {background-position: 20% 50%;}
.pageSupport .spotlight.featureB .ico::after {background-position: 40% 50%;}
.pageSupport .spotlight.featureC .ico::after {background-position: 60% 50%;}
.pageSupport .spotlight.featureD .ico::after {background-position: 80% 50%;}


.numberCircle {
    border-radius: 50%;
    width: 2em;
    height: 2em;
    padding: .375em .5em;
    text-align: center;
    font-size: 1em;
    line-height:1em;
    position: absolute;
    left: 9%;
    top: 2.5%;
    display:inline-block;
}


.bot::after, .service a::before, #solutions a::before {
	content: '';
	display:block;
	height: 0;
	background-image: url("workerbots.svg");
	background-position: 0 0;
}


#solutions {margin-bottom: 2em auto 100px;} 
#solutions a {
	width: 80px;
	margin: 1em;
	display:inline-block;
	text-align:center;
	position:relative;
	margin-bottom: 4em;
/* 
	border-radius: 50%;
 */
}
#solutions a span {
	display:inline-block;
	position:absolute;
	left:0px;
	width:100%;font-size:.9em;
}

#solutions a::before {
	width: 80%;
	padding-bottom: 80%;
	margin: 0px auto 5px;
	background-size: 600%;
}

.bot {
	padding: 5%;
	width:70%;
	margin:1em auto;
	border-radius: 50%;
}

.bot::after {
	width: 90%;
	padding-bottom: 90%;
	margin: 5%;
	background-size: 600%;
}

h1, #solutions a span, .service a h2 {position: relative;}
.copyright {
	font-size: .4em !important;
	position: absolute;
	top: 0em;
	margin-left: .35em;
	display:inline-block !important;
}

.service a {
  display:inline-block;
  width: 100%;
  padding: 5% 5% 5% 25%;
  margin-bottom: 20px;
  color:white;
  text-decoration:none;
  opacity: .9;
}

.service a:hover {
	opacity:1;
}
.service a h2 {
	margin:.15em auto;
	font-size: 1.25em;
	text-transform:uppercase;
}
.service a p {
	font-size:.95em;
	line-height:1.25em;
	color:#eee;
}
.service a::before {
	width: 16%;
	padding-bottom: 16%;
	margin: 0% 5.5%;
	background-size: 600%;
	position:absolute;
	left:0;
	top:12px;
}

/* 100% / (number_of_images – 1) */

.pageWebsites .bot::after, #setups.service a::before, #solutions a#setups::before {background-position: 60% 100%;}
.pageHosting .bot::after, #hosting.service a::before, #solutions a#hosting::before {background-position: 20% 0%;}
.pageGlideStep .bot::after, #glidestep.service a::before, #solutions a#glidestep::before {background-position: 40% 0%;}
.pageSupport .bot::after, #support.service a::before, #solutions a#agency::before, .pageContact .bot::after {background-position: 100% 0%;}
.pageSoundBoard .bot::after, #soundboard.service a::before, #solutions a#soundboard::before {background-position: 60% 0%;}
.pageGroundBridge .bot::after, #groundbridge.service a::before, #solutions a#groundbridge::before {background-position: 20% 100%;}
.pageSteamHook .bot::after, #steamhook.service a::before, #solutions a#steamhook::before {background-position: 0% 0%;}
.pageBoxKite .bot::after, #boxkite.service a::before, #solutions a#boxkite::before {background-position: 40% 100%;}
.pageMangedWebsites .bot::after, #sites.service a::before, #solutions a#sites::before {background-position: 80% 100%;}
.pageLeadCampaigns .bot::after, #leads.service a::before, #solutions a#leads::before {background-position: 0% 100%;}


.pageContact .intro {
	margin-bottom: 1em; 
}
.pageContact .intro p {
	line-height: 1.15em;
	margin-bottom: 1.5em; 
}

.getStarted { background-color:#ccc;}
#cta { background-color: #f5f8fa;}
#cta h3 { padding-top: 1em;}

.btn i {margin-right: .35em;}
.btn {border-radius:0px;}


.row.match-my-cols {
    overflow: hidden; 
}


.row.match-my-cols [class*="col-"]{
    margin-bottom: -99999px;
    padding-bottom: 99999px;
}



blockquote {
  border-left: 10px solid fade-out(#2196F3, .75);
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
  line-height: 1.25em;
  font-size: 1.25em;
}
blockquote:before {
  color: fade-out(#2196F3, .5);
  content: '\f10d';
  font-family: 'FontAwesome';
  font-size: 1.35em;
  line-height: 0.5em;
  margin-top: -0.15em;
  margin-right: 0.5em;
  vertical-align: -0.4em;
  float: left;
}
blockquote p {
  display: inline;
}
blockquote p.author {
  display: inline-block;
  width:100%;
  text-align:right;
  font-size: .85em;
  margin-top: 1em;
}
blockquote p.author .company {
  display: inline-block;
  width:100%;
  font-size: .85em;
  opacity: .75;
}

.mySocial {
  font-size: 3em;
}
.mySocial a {
	margin: 0em .15em 1em;
}
.person {
	font-size: .9em;
	margin: 1em auto 2em;
}
.person a {
	color: #333;
	display: block;
}
.person img {
	max-width: 100%;
	margin-bottom: .5em;
}
.person .position {
	display:block;
	font-size: .75em;
	opacity: .65;
}

#testimonials h2 {
	text-align:center;
	padding-top: 2em;
}



#invoices .pending { opacity: .5; color: gray;}
#invoices .due { opacity: 1;color: red;}
#invoices .paid { opacity: .5;color: green;}

#invoices .item a {color: inherit !important;}


#questionWrapper {
margin: 2em auto;
}
.questions {
	margin: 1em auto 2em;
}
.questions label {
	font-size: .95em !important;
	margin: 0em .15em .5em;
	line-height: 1.15em;
	font-weight:bold;
}

.pagePositions .details {
	text-align:left;
}
.pagePositions .details .row {
	margin-bottom: 1em !important;
}
.pagePositions .details .row .meta {
	font-size: .85em;
	font-weight: bold;
	margin-bottom: .5em;
}
.pagePositions .details .row li {
	font-size: .9em;
	margin-bottom: .75em;
}


.supportBlock {background: #ccc;}
.supportBlock h2, .supportBlock h3, .supportBlock p, .supportBlock .feature {color:#666;}
#features h2 {margin-top: 1.25em;}
.supportBlock, .technicalBlock {padding-bottom: 4em;}
.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; margin-bottom: 2.5em;} .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }




@media (min-width: 480px) {
  .row-xs-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .col-xs-height {
    display: table-cell;
    float: none;
    height: 100%;
  }
  .col-xs-top {
    vertical-align: top;
  }
  .col-xs-middle {
    vertical-align: middle;
  }
  .col-xs-bottom {
    vertical-align: bottom;
  }
}

@media (min-width: 768px) {
  .row-sm-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .col-sm-height {
    display: table-cell;
    float: none;
    height: 100%;
  }
  .col-sm-top {
    vertical-align: top;
  }
  .col-sm-middle {
    vertical-align: middle;
  }
  .col-sm-bottom {
    vertical-align: bottom;
  }
}

@media (min-width: 992px) {
  .row-md-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .col-md-height {
    display: table-cell;
    float: none;
    height: 100%;
  }
  .col-md-top {
    vertical-align: top;
  }
  .col-md-middle {
    vertical-align: middle;
  }
  .col-md-bottom {
    vertical-align: bottom;
  }
}

@media (min-width: 1200px) {
  .row-lg-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .col-lg-height {
    display: table-cell;
    float: none;
    height: 100%;
  }
  .col-lg-top {
    vertical-align: top;
  }
  .col-lg-middle {
    vertical-align: middle;
  }
  .col-lg-bottom {
    vertical-align: bottom;
  }

  .bigtop p {
    font-size: 1.25em;
  }

}





/* MOBILE ONLY */
@media (max-width: $screen-xs-max) {

/*
.navbar-default .navbar-nav > li > a {
	color: inherit;
}
.navbar-default .navbar-nav > li > a:hover {
	color: inherit;
}

p {
	color:red;
}
*/

.navbar-nav > li > a {line-height: 1.5em;}
.navbar-default .navbar-collapse {background: fade-out(black,.05);}
.white .navbar-default .navbar-collapse {background: fade-out(white,.05);}
.pageWebsites .navbar-default .navbar-collapse {background: fade-out(#8C1919,.05);}
.pageHosting .navbar-default .navbar-collapse {background: fade-out(#851445,.05);}
.pageGlideStep .navbar-default .navbar-collapse {background: fade-out(#237A72,.05);}
.pageSupport .navbar-default .navbar-collapse {background: fade-out(#171717,.05);}
.pageSoundBoard .navbar-default .navbar-collapse {background: fade-out(#0F5991,.055);}
.pageGroundBridge .navbar-default .navbar-collapse {background: fade-out(#7D1815,.055);}
.pageSteamHook .navbar-default .navbar-collapse {background: fade-out(#573e21,.055);}
.pageBoxKite .navbar-default .navbar-collapse {background: fade-out(#313e56,.055);}
.pageMangedWebsites .navbar-default .navbar-collapse {background: fade-out(#313e56,.055);}
.pageLeadCampaigns .navbar-default .navbar-collapse {background: fade-out(#313e56,.055);}


.pageHome #intro h1 {font-size:3.5em;font-size:9vw;margin: 1em auto .25em;}
.pageHome #intro p {font-size:1.25em;font-size:4.25vw;}
#intro h2 {font-size:3.5em;font-size:7.75vw;margin: 1.5em auto .25em;}
#intro p {font-size:1.25em;font-size:3.5vw;}


.spotlight {text-align:center;}

.ico::after {
	width: 100%;
	padding-bottom: 100%;
	margin: 1.5em 0% 1em;
}
.spotlight .ico::after {
	width: 79%;
	padding-bottom: 49%;
}

.numberCircle {
	top:-4%;
	left: 10%;
}


#audit .report .labels, #audit .report .data .row div {
	font-size: 1em;
	padding: .5em .25em;
}

.urlName {
	height: 13em;
	margin-top: .75em;
	margin-bottom: .15em;
/* 	left: 0%; */
}

.urlName span {
	transform: rotate(-90deg);
}

.nav-tabs li {font-size: .55em;}
.levels {font-size: .65em;padding: 0em;}
.budget {font-size: .85em;}
.hours {font-size: .5em;letter-spacing: -.05em;}


}

/* SM & UP ONLY */
@media (min-width: $grid-float-breakpoint) {

.spotlight {
	font-size: 1.25em;
}

.logo {
	width:15%;
	height:10%;
	margin: .5%;
}

.pricingTable {font-size: 1.25em;}

/* 
.spotlight {
	font-size: 1.85vw;
}
 */

}


